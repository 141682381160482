import { SHOW_MESSAGE, HIDE_MESSAGE } from 'components/Message/messageActionTypes';

export const showMessage = (variant, text, title, showCloseButton, timeout, translate = true) => (
  dispatch,
) => {
  dispatch({
    type: SHOW_MESSAGE,
    variant,
    text,
    title,
    showCloseButton,
    timeout,
    translate,
  });
};
export const hideMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE,
  });
};

export default { showMessage };
