import { FILE_MAX_SIZE, MAX_SECONDS, MESSAGE_STATE } from 'config/constants';

const PASSWORD_REGEX = new RegExp(
  '^' + // Start anchor
  '(?=.*[A-Z])' + // Ensure string has at least one uppercase letter.
  '(?=.*[0-9])' + // Ensure string has at least one digit.
  '(?=.*[a-z])' + // Ensure string has at least one lowercase letter.
  '.{8,}' + // Ensure string is at least of length 8.
    '$', // End anchor.
);
const EMAIL_REGEXP = new RegExp(
  '^' + // Start anchor
  '[a-z0-9_.+\\-]+' + // Ensure no extra chars in the name.
  '@' + // Ensure @ sign in the middle.
  '[a-z0-9]([a-z0-9-]*[a-z0-9])?' + // Ensure clean domain name.
  '(\\.[a-z0-9]([a-z0-9-]*[a-z0-9]))+' + // Ensure >=1 top level domains.
    '$', // End anchor.
);

const NAME_REGEX = new RegExp(/^[A-Za-z0-9_ )([\]\-+,/.]+$/);

class Validator {
  password = (password) => password.length > 0 && PASSWORD_REGEX.test(password);
  email = (email) => email.length > 0 && EMAIL_REGEXP.test(email);
  missionName = (missionName) =>
    missionName.length >= 2 && missionName.length <= 47 && NAME_REGEX.test(missionName);
  trim = (value) => value.trimRight();
  company = (company) => this.trim(company).length >= 4;
  username = (username) => this.trim(username).length >= 4;
  name = (name) => this.trim(name).length >= 4;
  duration = (duration) =>
    this.positiveInteger(duration) && duration > 0 && duration <= MAX_SECONDS;
  positiveInteger = (value) => /^[0-9]\d*$/.test(value);
  positiveFloat = (value) => /^\d+(\.\d)?$/.test(value);
  isValidArray = (value) => Array.isArray(value) && value.length > 0;
  id = (id) => this.positiveInteger(id) && id > 0 && id <= 999999;
  units = (units) => this.positiveFloat(units);
  maxActiveSessions = (maxActiveSessions) =>
    this.positiveInteger(maxActiveSessions) && maxActiveSessions >= 0;
  log = (log) => this.positiveInteger(log) && log > 0;
  key = (key) => this.positiveInteger(key) && key > 0;
  match = ({ value, verification }) => value === verification;
  validate = (name, value) => {
    let isValid = this[name] && this[name](value);
    if (isValid) {
      return { isValid };
    }
    return { isInvalid: true };
  };
  isFormValid = (form) =>
    !Object.keys(form).filter((name) => this[name] && !this[name](form[name])).length;
  isAccountFormValid = (password, verification) => {
    const form = {};
    if (password.length) {
      form.password = password;
    }
    form.match = { value: password, verification };
    return this.isFormValid(form);
  };
  coffeeScript = (text) => !window.coffeelint || window.coffeelint.lint(text).length == 0;
  isAdminAccountFormValid = (form) => this.isFormValid(form);
  messageTitle = (value) => (value ? value.length >= 0 : true);
  messageText = (value) => (value ? value.length >= 0 : true);
  message = ({ state, title, text }) =>
    this.messageTitle(title) &&
    this.messageText(text) &&
    Object.values(MESSAGE_STATE).includes(state);
  availableLanguages = (languages) => this.isValidArray(languages);
  availableGroups = (languages) => this.isValidArray(languages);
  isUserFormValid = ({
    id,
    company,
    username,
    units,
    maxActiveSessions,
    active,
    password,
    verification,
    unitsEnabled,
    availableLanguages,
    availableGroups,
  }) => {
    const form = {
      id,
      company,
      username,
      maxActiveSessions,
      active,
      availableLanguages,
      availableGroups,
    };
    if (password.length) {
      form.password = password;
    }
    form.match = { value: password, verification };
    if (unitsEnabled) {
      form.units = units;
    }
    return this.isFormValid(form);
  };
  isServerFormValid = ({ message, key, log }) => {
    const form = {
      message,
      key,
      log,
    };
    return this.isFormValid(form);
  };
  isValidFile = (checkPoints, file) => {
    let arr = file.name.split('.');
    const extension = arr[arr.length - 1].toLowerCase();
    return checkPoints.indexOf(extension) !== -1;
  };

  isValidFileSize = (file) => file.size <= FILE_MAX_SIZE;

  isValidMission = ({ name, units, script }) =>
    this.missionName(name) &&
    this.positiveFloat(units) &&
    script.length > 0 &&
    this.coffeeScript(script);

  isVideoFormValid = ({ name, duration }) => {
    const form = {
      name,
      duration,
    };
    return this.isFormValid(form);
  };
}
const validator = new Validator();

export default validator;
