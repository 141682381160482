import { combineReducers } from 'redux';
import loginReducer from 'components/Login/loginReducer';
import sessionsReducer from 'components/Session/sessionsReducer';
import missionsReducer from 'components/Mission/missionsReducer';
import teamReducer from 'components/Mission/teamReducer';
import userReducer from 'components/User/userReducer';
import resourceReducer from 'components/Resource/resourceReducer';
import adminMissionReducer from 'components/AdminMission/adminMissionReducer';
import clientReducer from 'components/Client/clientReducer';
import videoReducer from 'components/Video/videoReducer';
import messageReducer from 'components/Message/messageReducer';
import loadingReducer from 'components/Loading/loadingReducer';
import settingsReducer from 'components/Settings/settingsReducer';

const allReducers = combineReducers({
  user: loginReducer,
  sessions: sessionsReducer,
  missions: missionsReducer,
  users: userReducer,
  teams: teamReducer,
  resources: resourceReducer,
  adminMissions: adminMissionReducer,
  clients: clientReducer,
  videos: videoReducer,
  message: messageReducer,
  loading: loadingReducer,
  settings: settingsReducer,
});

export default allReducers;
