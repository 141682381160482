import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const TabHeader = ({ t, clientId }) => (
  <>
    <FontAwesomeIcon icon={faEye} className="eye-icon" />
    <span className="team-indicator">{`${t('_room_')} ${clientId}`}</span>
  </>
);
TabHeader.propTypes = {
  clientId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default TabHeader;
