import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import BooleanRow from 'components/TimeLine/Components/BooleanRow';

const Defects = ({ t, data, formattedData }) => (
  <div className="time-line-view-body">
    <SectionHeader t={t} title="_defects_" />
    <SectionRow t={t} title="_0_deck_">
      <BooleanRow
        length={formattedData.length}
        data={formattedData.chartData.defects.main}
        cellKey="main"
        groupKey="defects"
        color="#b2b2b2"
      />
    </SectionRow>
    <SectionRow t={t} title="_1_deck_">
      <BooleanRow
        length={formattedData.length}
        data={formattedData.chartData.defects.support}
        cellKey="support"
        groupKey="defects"
        color="#b2b2b2"
      />
    </SectionRow>
    <SectionRow t={t} title="_2_deck_">
      <BooleanRow
        length={formattedData.length}
        data={formattedData.chartData.defects.commander}
        cellKey="commander"
        groupKey="defects"
        color="#b2b2b2"
      />
    </SectionRow>
  </div>
);

Defects.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number,
    chartData: PropTypes.shape({
      defects: PropTypes.shape({
        main: PropTypes.arrayOf(PropTypes.shape({})),
        support: PropTypes.arrayOf(PropTypes.shape({})),
        commander: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};
export default Defects;
