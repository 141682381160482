import axios from 'axios';
import { refreshToken } from 'components/Login/loginActions';
import { API_URL } from 'config/constants';
import { ACCOUNT_OPEN_SESSIONS } from 'components/Account/accountActionsTypes';

export const getActiveSessions = (refresh = true) => (dispatch) => {
  axios.get(`${API_URL}account/activeSessions`).then(({ data }) => {
    dispatch({
      type: ACCOUNT_OPEN_SESSIONS,
      activeSessions: data.result,
    });
    refresh && dispatch(refreshToken(() => {}));
  });
};
export const update = (state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}account`, state).then(() => {
    dispatch(refreshToken(updateCallback));
  });
};
export const getAdminAccount = (getCallback) => {
  axios.get(`${API_URL}account/settings`).then(({ data }) => getCallback(data));
};
export const updateAdminAccount = (state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}account/settings`, state).then(() => {
    dispatch(refreshToken(updateCallback));
  });
};

export default { getActiveSessions, update, getAdminAccount, updateAdminAccount };
