import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faPlug, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { shutDown, NOOP } from 'common/deviceApi/deviceApi';
import { logout } from 'components/Login/loginActions';
import Notifier from 'components/Message/Notifier';
import { serverInfo } from 'components/User/userActions';

const Logout = ({ handleClose, show, showPowerOff }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [server, setServer] = useState({});

  const handleLogOut = useCallback(() => {
    handleClose(true);
    dispatch(
      logout(() => {
        i18n.changeLanguage('en');
        history.entries = [];
        history.index = -1;
        history.replace('/login');
        Notifier.hideMessage();
        setTimeout(() => {
          location.reload();
        }, 0);
      }),
    );
  }, [dispatch, handleClose, history]);

  const handleshutDown = useCallback(() => {
    shutDown(handleLogOut);
  }, [handleLogOut]);

  useEffect(() => {
    serverInfo(setServer);
  }, []);

  const isLocalServer = useMemo(() => server && server.mode == '_local_server_', [server]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> {`${t('_logout_mission_msg_')}`} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Row>
              <Col xs="auto">
                <Button variant="danger" onClick={handleLogOut}>
                  <FontAwesomeIcon icon={faPowerOff} />
                  <span className="pl-2">{`${t('_logout_btn_text_')}`}</span>
                </Button>
              </Col>
              {!!isLocalServer && !!showPowerOff && (
                <Col xs="auto">
                  <Button variant="danger" onClick={handleshutDown}>
                    <FontAwesomeIcon icon={faPlug} />
                    <span className="pl-2">{`${t('_power_off_btn_text_')}`}</span>
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs="auto">
            <Button variant="success" onClick={handleClose}>
              <span className="pr-2">{` ${t('_logout_continue_text_')}`}</span>
              <FontAwesomeIcon icon={faPlayCircle} />
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
Logout.propTypes = {
  show: PropTypes.bool.isRequired,
  showPowerOff: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

Logout.defaultProps = {
  handleClose: NOOP,
};

export default Logout;
