import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import DeckJobInfo from 'components/Mission/lib/JobInfo/DeckJobInfo';
import { GAME_DECKS } from 'config/constants';

const JobInfoList = ({ teamIndex }) => {
  const infoJob = useSelector(({ teams }) => teams[teamIndex].infoJob);
  return (
    <div className="info-job-container">
      <ListGroup>
        <ListGroup.Item>
          <DeckJobInfo data={infoJob[GAME_DECKS.MAIN.id]} deck={GAME_DECKS.MAIN.id} />
        </ListGroup.Item>
        <ListGroup.Item>
          <DeckJobInfo data={infoJob[GAME_DECKS.SUPPORT.id]} deck={GAME_DECKS.SUPPORT.id} />
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

JobInfoList.propTypes = {
  teamIndex: PropTypes.number.isRequired,
};

export default JobInfoList;
