import { INIT_SETTINGS, USB_KSY_CHANGED } from 'components/Settings/settingsActionTypes';

export const initialState = { languages: [], groups: [], usbKey: '' };

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_SETTINGS:
      return { ...state, languages: action.languages, groups: action.groups };
    case USB_KSY_CHANGED:
      return { ...state, usbKey: action.usbKey };
    default:
      return state;
  }
}
