import React from 'react';
import PropTypes from 'prop-types';

const SectionRow = ({ t, title, children, doubleLine, isChart, color, data }) => (
  <div className="time-line-view-body-row">
    <div className={`time-line-view-body-row-table ${doubleLine ? 'double-line' : ''}`}>
      <div style={{ backgroundColor: color }}>{t(title)}</div>
      {Array(10)
        .fill(1)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`time-line-view-body-row-${title}-${index}}`}>
            <div className="dashed" />
            <div />
          </div>
        ))}
    </div>
    <div className={`time-line-view-body-row-content ${isChart ? 'chart' : ''}`}>{children}</div>
    <div className="error-point-row">
      {data.map((point) => (
        <div
          className="error-point-row-item"
          key={point.key}
          style={{
            left: `calc(${point.left.toFixed(2)}% - 11px)`,
          }}
        />
      ))}
    </div>
  </div>
);
SectionRow.defaultProps = {
  children: null,
  doubleLine: false,
  isChart: false,
  color: 'transparent',
  data: [],
};

SectionRow.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  doubleLine: PropTypes.bool,
  isChart: PropTypes.bool,
  color: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
export default SectionRow;
