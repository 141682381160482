import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  0: 'transparent',
  1: '#A4FAA0',
  2: '#51F048',
  3: '#47D141',
  4: '#3AAA35',
};

const NumberRow = ({ data, groupKey, cellKey, step, stepCount, colored }) => (
  <>
    {Array(stepCount)
      .fill(step)
      .map(function (i, index) {
        const item = data[(1 + index) * step][groupKey];
        const value = typeof item[cellKey] === 'number' ? item[cellKey] : item[cellKey].value;
        return (
          <div
            className="time-line-view-body-row-content-number"
            style={{ backgroundColor: colors[colored ? value : 0] }}
            // eslint-disable-next-line react/no-array-index-key
            key={`time-line-view-body-row-content-number-${groupKey}-${cellKey}-${i}-${index}`}
          >
            {value}
          </div>
        );
      })}
  </>
);

NumberRow.defaultProps = {
  colored: false,
};

NumberRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  cellKey: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
  colored: PropTypes.bool,
};
export default NumberRow;
