import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useNavbar } from 'common/components';
import ScoreTabs from 'components/Scores/ScoreTabs';
import { list } from 'components/Scores/scoreActions';
import PageSwitch from 'components/Mission/lib/PageSwitch/PageSwitch';
import TimeLineScreen from 'components/Scores/TimeLine';
import TrainerScreen from 'components/Scores/Trainer';
import PrintableTable from 'components/Scores/PrintableTable';
import { RESULT_SWITCH } from 'config/constants';
import Notifier from 'components/Message/Notifier';

const getFullTeams = (scores) => {
  let diff = 0;
  for (let i = 0; i < scores.length; i++) {
    const score = scores[i];
    diff = (score.displayClientId || score.clientId) - score.clientId;
    continue;
  }
  return Array(4)
    .fill(0)
    .map((item, index) => ({
      displayClientId: index + diff + 1,
      clientId: index + 1,
    }));
};

const Scores = () => {
  const { t } = useTranslation();
  const { sessionId, missionId } = useParams();
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [scores, setScores] = useState([]);
  const [active, setActive] = useState(RESULT_SWITCH.behaviour.id);
  const [records, setRecords] = useState({});
  const [timeLine, setTimeLine] = useState({});
  const [formattedTimeLine, setFormattedTimeLine] = useState({});
  const [duration, setDuration] = useState(0);
  const [renderPrint, setRenderPrint] = useState(false);
  const fullTeams = useMemo(() => getFullTeams(scores), [scores]);

  const getActivePage = (duration) => {
    switch (active) {
      case RESULT_SWITCH.behaviour.id:
        return <ScoreTabs t={t} name={name} date={date} scores={scores} fullTeams={fullTeams} />;
      case RESULT_SWITCH.timeLine.id:
        return (
          timeLine && (
            <TimeLineScreen
              timeLine={timeLine}
              fullTeams={fullTeams}
              duration={duration}
              formattedTimeLine={formattedTimeLine}
            />
          )
        );
      case RESULT_SWITCH.trainer.id:
        return records && <TrainerScreen records={records} fullTeams={fullTeams} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    Notifier.loading(true);
    list(sessionId, missionId, (data) => {
      Notifier.loading(false);
      setName(data.name);
      setDate(data.date);
      setDuration(data.duration || 0);
      setScores(data.scores);
      setRecords(data.records);
      setTimeLine(data.timeLine);
      setFormattedTimeLine(data.formattedTimeLine);
    });
  }, [missionId, sessionId]);
  const allowed = [RESULT_SWITCH.behaviour.id];
  if (timeLine) {
    allowed.push(RESULT_SWITCH.timeLine.id);
  }
  if (records) {
    allowed.push(RESULT_SWITCH.trainer.id);
  }
  const showToolbar = !!(timeLine || records);

  const printHandler = () => {
    setRenderPrint(true);
    setTimeout(() => {
      print();
      setRenderPrint(false);
    }, 1000);
  };
  return (
    <Container>
      {useNavbar(t('_scores_'))}
      <div className="scores-page mt-3 mb-3">
        <Row className="my-4 d-print-none">
          <Col xs={4} md={6} lg={7} xl={8}>
            {showToolbar && (
              <PageSwitch t={t} active={active} switchScreen={setActive} allowed={allowed} />
            )}
          </Col>
          <Col xs={4} md={3} lg={2} xl={2} />
          <Col xs={4} md={3} lg={2} xl={2}>
            <Button variant="success" className="print-btn-container" block onClick={printHandler}>
              <FontAwesomeIcon icon={faPrint} />
              {` ${t('_print_')}`}
            </Button>
          </Col>
        </Row>
        {getActivePage(duration)}
        {renderPrint && (
          <PrintableTable
            data={scores}
            name={name}
            date={date}
            fullTeams={fullTeams}
            timeLine={timeLine}
            formattedTimeLine={formattedTimeLine}
            duration={duration}
          />
        )}
      </div>
    </Container>
  );
};
export default Scores;
