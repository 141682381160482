import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import useBeforeUnload from 'use-before-unload';
import moment from 'moment';
import { useNavbar } from 'common/components';
import CustomBreadCrumb from 'common/CustomBreadCrumb/CustomBreadCrumb';
import Steps from 'components/Mission/Steps';
import { missionsPT } from 'components/Mission/PropTypes';
import { userPT } from 'components/User/PropTypes';
import { get, getMeta } from 'components/Session/sessionActions';
import {
  updateTeams,
  updateTeam,
  disconnect,
  playMission,
  sessionState,
  sessionData,
  abortSession,
  completeSession,
  getGame,
  initTeams,
  resetTeams,
  updateTeamByKey,
  updateTags,
  removeTag,
  resetTags,
} from 'components/Mission/missionActions';
import { refreshToken } from 'components/Login/loginActions';
import { GAME_STEPS, STATUS, ERRORS } from 'config/constants';
import sessionManager from 'common/session/session.manager';
import Notifier from 'components/Message/Notifier';
import TitleComponent from 'components/Mission/lib/TitleComponent';
import { areObjectsDifferent } from 'utils/common';

const defaultMission = { name: '', type: '_mission_', units: 0 };
let isReloading = false;

export const gameStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, isReady, isGameIsStarted, clients }) =>
      !previous.find(
        (team) =>
          team.clientId == clientId &&
          Boolean(team.isReady) == Boolean(isReady) &&
          Boolean(team.isGameIsStarted) == Boolean(isGameIsStarted) &&
          (team.clients === clients || (team.clients || []).length === (clients || []).length),
      ),
  );

/// TODO: Optimize reload and game start logic
export const Mission = ({ missions, user }) => {
  const teams = useSelector(
    ({ teams }) =>
      teams.map(({ clientId, isReady, isGameIsStarted }) => ({
        clientId,
        isReady,
        isGameIsStarted,
      })),
    gameStatusWasChanged,
  );
  const { sessionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gameIsStarted = !!teams.filter((team) => team.isGameIsStarted).length;
  const [currentStep, setCurrentStep] = useState(gameIsStarted ? 3 : 1);
  const [session, setSession] = useState({ clientKeys: [] });
  const [sessionHistory, setSessionHistory] = useState({ count: 0, _id: {} });
  const [currentMission, setCurrentMission] = useState(JSON.parse(JSON.stringify(defaultMission)));
  const [videoTeams, setVideoTeams] = useState({ videoRooms: [], roles: [] });
  const [result, setResult] = useState({});
  const [isGameAborted, setIsGameAborted] = useState(false);
  const [fullTeams, setFullTeams] = useState([]);
  const [sessionStateData, setSessionState] = useState({});
  const [sessionInfo, setSessionInfo] = useState({});
  const [game, setGame] = useState(null);
  const [currentGame, setCurrentGame] = useState(0);
  // OPTIMIZE GAME OBSERVATION LOGIC AND RENDER FLOW !!!!
  const [isAfterSave, setAfterSave] = useState(false);
  const _socket = useRef(null);

  const initState = useCallback((mission, step, date) => {
    const result = {};
    /* istanbul ignore else  */
    if (date) {
      result.date = date;
    }
    setResult(result);
    setCurrentStep(step);
    setCurrentMission(mission);
    setVideoTeams({ videoRooms: [], roles: [] });
  }, []);

  const initResultTab = useCallback(
    (game) => {
      const aborted = isGameAborted || !game.gameId;
      dispatch(
        refreshToken(() => {
          /* istanbul ignore else */
          Notifier.loading(false);
          setCurrentStep(4);
          if (!aborted && game.gameId && (!result || result._id !== game.gameId)) {
            const mission = missions.filter((mission) => mission._id === game.missionId)[0];
            setResult(game);
            setIsGameAborted(aborted);
            if (
              mission &&
              mission._id === currentMission._id &&
              areObjectsDifferent(currentMission, mission)
            ) {
              setCurrentMission(mission);
            }
          }
        }),
      );
    },
    // eslint-disable-next-line no-use-before-define
    [currentMission, dispatch, isGameAborted, missions, result],
  );

  result.date = moment(result.date || session.datePlayed || new Date()).format('YYYY-MM-DD HH:mm');
  result.name = currentMission.name;

  const switchGame = useCallback(
    (clientId) => {
      _socket.current && _socket.current.switchGame({ clientId });
    },
    [_socket],
  );
  const collectSessionData = useCallback(
    (info, state) => {
      info = info || sessionInfo || {};
      state = state || sessionStateData || {};
      if (state.status !== STATUS.NOT_STARTED) {
        const mission = missions.filter((mission) => mission._id === info.missionId)[0];
        if (mission && mission.type === '_video_' && areObjectsDifferent(info, videoTeams)) {
          setVideoTeams(info);
        }
        if (mission) {
          currentMission.duration = mission.duration;
          currentMission.name = mission.name;
          currentMission.type = mission.type;
          currentMission._id = mission._id;
        }
        if (state.status === STATUS.OBSERVE) {
          setCurrentStep((current) => {
            if (current != 4) {
              return 3;
            }
            return current;
          });
          _socket.current && _socket.current.gameId && switchGame(_socket.current.gameId);
        } else if (state.status === STATUS.GAME_SAVED && game) {
          initResultTab(game);
        } else if (state.status === STATUS.ABORT) {
          setCurrentStep(4);
        }
      }
    },
    // eslint-disable-next-line no-use-before-define
    [
      currentMission._id,
      currentMission.duration,
      currentMission.name,
      currentMission.type,
      game,
      initResultTab,
      missions,
      sessionInfo,
      sessionStateData,
      switchGame,
      videoTeams,
    ],
  );
  const play = useCallback(
    (info, _next) => {
      dispatch(
        refreshToken(() => {
          if (info.units <= user.units) {
            const activeTeams = {
              active: info.teams
                .map((team) => {
                  if (team.isReady) {
                    return team.key;
                  }
                })
                .filter((item) => item),
              roles: [],
              selected: info.teams
                .map((team) => {
                  if (team.selected) {
                    return team.key;
                  }
                })
                .filter((item) => item),
            };
            if (info.main) {
              activeTeams.roles.push(0);
            }
            if (info.support) {
              activeTeams.roles.push(1);
            }
            if (info.commander) {
              activeTeams.roles.push(2);
            }
            if (activeTeams.selected.length) {
              playMission(
                sessionId,
                currentMission._id,
                {
                  teams: activeTeams.selected,
                  roles: activeTeams.roles,
                },
                () => {
                  sessionState(sessionId, (stateData) => {
                    sessionData(sessionId, (sessionInfo) => {
                      setSessionInfo({ ...sessionInfo, sessionId });
                      const state = { ...stateData, sessionId };
                      const info = { ...sessionInfo, sessionId };
                      setSessionState();
                      _next(currentStep);
                      if (currentMission.type !== '_video_') {
                        let clientId = 0;
                        teams.forEach((team) => {
                          if (team.isReady && !clientId) {
                            clientId = team.clientId;
                          }
                        });
                        switchGame(clientId);
                      } else {
                        // eslint-disable-next-line no-use-before-define
                        collectSessionData(info, state);
                      }
                    });
                  });
                },
              );
            }
          } else {
            Notifier.error('sysERR_USER_INSUFFICIENT_FUND');
          }
        }),
      );
    },
    // eslint-disable-next-line no-use-before-define
    [
      dispatch,
      user.units,
      sessionId,
      currentMission._id,
      currentMission.type,
      currentStep,
      teams,
      switchGame,
      collectSessionData,
    ],
  );
  const prev = useCallback((step) => {
    setCurrentStep(step > 1 ? step - 1 : step);
  }, []);

  const next = useCallback(
    (step, data) => {
      if (data && data.play) {
        play(data, next);
      } else {
        setCurrentStep(step < 4 ? step + 1 : step);
      }
    },
    [play],
  );

  const getFullTeams = (data) => {
    /* istanbul ignore next */
    let diff = 0;
    for (let i = 0; i < data.length; i++) {
      const team = data[i];
      diff = team.displayClientId - team.clientId;
      break;
    }
    /* istanbul ignore next */
    return Array(6)
      .fill(0)
      .map((item, index) => {
        const team = data.find((item) => item.clientId == index + 1) || {
          clientId: index + 1,
        };
        return {
          ...team,
          displayClientId: index + diff + 1,
        };
      });
  };

  const clientJoined = useCallback(
    (data) => {
      _socket.current.gameId = null;
      data.forEach(
        (team) =>
          team.isReady && !_socket.current.gameId && (_socket.current.gameId = team.clientId),
      );
      /* istanbul ignore next */
      setFullTeams(getFullTeams(data));
      dispatch(updateTeams(data));
    },
    [dispatch],
  );

  const totalsReceived = useCallback((data) => dispatch(updateTeam(data)), [dispatch]);
  const remainingTimeReceived = useCallback((data) => dispatch(updateTeam(data)), [dispatch]);

  useBeforeUnload(() => {
    isReloading = true;
    return false;
  });

  const onDisconnect = (error) => {
    /* istanbul ignore next */
    if (error === ERRORS.network_error.transport && !isReloading) {
      dispatch(resetTeams());
      setFullTeams(
        (fullTeams || []).map((team) => {
          team.clients = [];
          return team;
        }),
      );
      setCurrentMission(JSON.parse(JSON.stringify(defaultMission)));
      // eslint-disable-next-line no-use-before-define
      registerSocketListerners();
      Notifier.error('_server_disconnected_');
    }
  };

  const onGameError = useCallback((data) => Notifier.error(data.error), []);
  const onGameAbort = useCallback(() => setIsGameAborted(true), []);

  const missionComplete = useCallback(
    (data) => {
      if (data && data.length) {
        Notifier.loading(true);
      } else {
        setIsGameAborted(true);
        setSessionState({ ...sessionStateData, status: STATUS.ABORT });
      }
    },
    [sessionStateData],
  );

  const onGameSaved = useCallback(
    ({ dbId }) =>
      dispatch(
        getGame(sessionId, dbId, (data) => {
          setGame(data);
          initResultTab({ ...data, gameId: dbId });
          if (!sessionHistory.count) {
            getMeta(sessionId, setSessionHistory);
          }
        }),
      ),
    [dispatch, initResultTab, sessionHistory.count, sessionId],
  );

  const observe = useCallback(
    (data) => {
      _socket.current.gameId = data.clientId;
      setCurrentGame(data.clientId * 1);
      dispatch(updateTeam(data));
    },
    [dispatch],
  );
  const onTagAdded = useCallback(
    (tags) => {
      dispatch(updateTags(tags));
    },
    [dispatch],
  );
  const onTagRemoved = useCallback(
    (tag) => {
      dispatch(removeTag(tag));
    },
    [dispatch],
  );

  const unregisterListeners = useCallback(() => {
    if (_socket.current) {
      _socket.current.offByIds([
        'mission-complete',
        'client-joined',
        'totals-received',
        'time-received',
        'licensee-disconnect',
        'mission-page-disconnect',
        'game-error',
        'game-aborted',
        'game-saved',
        'observe',
        'tag-added',
        'tag-removed',
      ]);
    }
  }, [_socket]);

  const registerSocketListerners = () => {
    if (_socket.current) {
      _socket.current.loadTeamStatuses();
      _socket.current.missionComplete(missionComplete, 'mission-complete');
      _socket.current.clientJoined(clientJoined, 'client-joined');
      _socket.current.totalsReceived(totalsReceived, 'totals-received');
      _socket.current.remainingTimeReceived(remainingTimeReceived, 'time-received');
      _socket.current.onLicenseeDisconnect(onDisconnect, 'licensee-disconnect');
      _socket.current.onDisconnect(onDisconnect, 'mission-page-disconnect');
      _socket.current.onGameError(onGameError, 'game-error');
      _socket.current.onGameAbort(onGameAbort, 'game-aborted');
      _socket.current.onGameSaved(onGameSaved, 'game-saved');
      _socket.current.observe(observe, 'observe');
      _socket.current.onTagAdded(onTagAdded, 'tag-added');
      _socket.current.onTagRemoved(onTagRemoved, 'tag-removed');
    }
  };

  const disconnectTeams = useCallback(() => {
    disconnect(sessionId, () => {
      dispatch(initTeams());
      window.location.reload();
    });
  }, [sessionId, dispatch]);

  const playCallback = useCallback(
    (mission) => {
      setIsGameAborted(false);
      next(currentStep);
      setCurrentMission(mission);
    },
    [currentStep, next],
  );
  const handleAbort = useCallback(
    (id = 'all') => {
      abortSession(sessionId, id, () => {
        if (id === 'all') {
          initState(
            { ...defaultMission, type: currentMission.type, name: currentMission.name },
            4,
            new Date(),
          );
          setSessionState({ ...sessionStateData, status: STATUS.ABORT });
          dispatch(resetTeams());
          _socket.current.loadTeamStatuses();
        } else {
          dispatch(
            updateTeamByKey({
              key: id,
              isReady: false,
              total: undefined,
            }),
          );
        }
      });
    },
    [sessionId, initState, currentMission.type, currentMission.name, sessionStateData, dispatch],
  );

  const changeStateOnComplete = () => {
    setCurrentStep(1);
    setCurrentMission(defaultMission);
    setSessionState({});
    setSessionInfo({});
    setGame({});
    setAfterSave(false);
    dispatch(resetTags());
  };

  useEffect(() => {
    if (isAfterSave) {
      changeStateOnComplete();
    }
  }, [changeStateOnComplete, isAfterSave]);

  const handleComplete = useCallback(() => {
    if (isAfterSave) {
      setAfterSave(false);
    }
    completeSession(sessionId, () => {
      dispatch(
        refreshToken(() => {
          _socket.current.loadTeamStatuses();
          setAfterSave(true);
        }),
      );
    });
  }, [dispatch, isAfterSave, sessionId]);

  const sendMessage = useCallback((data) => {
    _socket.current && _socket.current.sendMessage(data);
  }, []);

  useEffect(() => {
    get(sessionId, (session) => {
      _socket.current = sessionManager.getSession(session.key);
      registerSocketListerners();
      setSession(session);
      sessionState(sessionId, (stateData) => {
        setSessionState({ ...stateData, sessionId });
        if (stateData.status === STATUS.GAME_SAVED) {
          Notifier.loading(true);
          dispatch(
            getGame(sessionId, stateData.gameId, (data) => {
              setGame(data);
              initResultTab({ ...data, gameId: stateData.gameId });
            }),
          );
        }
      });
      sessionData(sessionId, (sessionInfo) => {
        setSessionInfo({ ...sessionInfo, sessionId });
      });
      getMeta(sessionId, setSessionHistory);
    });
    return () => {
      unregisterListeners();
      _socket.current && (_socket.current.gameId = null);
      dispatch(resetTeams());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);
  useEffect(() => {
    collectSessionData();
  }, [collectSessionData]);

  const addTag = useCallback((clientId, tagId) => {
    _socket.current && _socket.current.addTag({ clientId, tagId });
  }, []);

  const getSuffix = () => {
    const hasKey = currentStep == 1;
    if (hasKey && session.clientKeys.length) {
      const keyOne = session.clientKeys.find((key) => key.clientId == 1);
      const keyTwo = session.clientKeys.find((key) => key.clientId == 2);
      const keyThree = session.clientKeys.find((key) => key.clientId == 3);
      const keyFour = session.clientKeys.find((key) => key.clientId == 4);
      /* istanbul ignore next */
      const missionControl = session.clientKeys.find((key) => key.clientId == 5) || {};
      /* istanbul ignore next */
      const waitingRoom = session.clientKeys.find((key) => key.clientId == 6) || {};
      return (
        <>
          <br />
          {'( '}
          {<FontAwesomeIcon icon={faKey} />}
          <span className="all-keys">
            <b> {session.key} </b>
            <b> | </b>
            <b disabled={!keyOne.isOpen}>{keyOne.key}</b>
            <b> | </b>
            <b disabled={!keyTwo.isOpen}>{keyTwo.key}</b>
            <b> | </b>
            <b disabled={!keyThree.isOpen}>{keyThree.key}</b>
            <b> | </b>
            <b disabled={!keyFour.isOpen}>{keyFour.key}</b>
            <b> | </b>
            <b disabled={!missionControl.isOpen}>{missionControl.key}</b>
            <b> | </b>
            <b disabled={!waitingRoom.isOpen}>{waitingRoom.key}</b>
          </span>
          {' )'}
        </>
      );
    }
    const mission = missions.find((mission) => mission._id == currentMission._id);
    return <>{mission ? ` / ${mission.name}` : ''}</>;
  };

  return (
    <Container>
      {useNavbar(
        <TitleComponent prefix={session.name} suffix={getSuffix()} />,
        false,
        true,
        session,
        fullTeams,
        false,
      )}
      <CustomBreadCrumb t={t} currentStep={currentStep} steps={GAME_STEPS} />
      <Steps
        currentStep={currentStep}
        session={session}
        historyExist={sessionHistory.count > 0}
        missions={missions}
        disconnectTeams={disconnectTeams}
        playCallback={playCallback}
        t={t}
        videoTeams={videoTeams}
        prev={(step) => prev(step)}
        next={(step, data) => next(step, data)}
        currentMission={currentMission}
        user={user}
        abortSession={handleAbort}
        isGameAborted={isGameAborted}
        finishMission={handleComplete}
        switchGame={switchGame}
        result={result}
        fullTeams={fullTeams}
        sendMessage={sendMessage}
        addTag={addTag}
        currentGame={currentGame}
      />
    </Container>
  );
};

Mission.propTypes = {
  missions: missionsPT.isRequired,
  user: userPT.isRequired,
};

const mapStateToProps = (state) => ({
  missions: state.missions,
  user: state.user,
});

export default connect(mapStateToProps, null)(Mission);
