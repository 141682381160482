import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Video from 'components/Mission/lib/Video';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPrint } from '@fortawesome/free-solid-svg-icons';
import { resultPT } from 'components/Mission/lib/PropTypes';
import ScoreTabs from 'components/Scores/ScoreTabs';
import PageSwitch from 'components/Mission/lib/PageSwitch/PageSwitch';
import TimeLineScreen from 'components/Scores/TimeLine';
import TrainerScreen from 'components/Scores/Trainer';
import PrintableTable from 'components/Scores/PrintableTable';
import { RESULT_SWITCH, SESSION_KEY_TYPE } from 'config/constants';

const defaultResult = {
  name: '',
  date: '',
  scores: [],
};

const Results = ({ t, isVideo, videoTeams, finishMission, result, isGameAborted, fullTeams }) => {
  const [active, setActive] = useState(RESULT_SWITCH.behaviour.id);
  const [renderPrint, setRenderPrint] = useState(false);
  const getActivePage = () => {
    switch (active) {
      case RESULT_SWITCH.behaviour.id:
        return (
          <ScoreTabs
            t={t}
            name={result.name || defaultResult.name}
            date={result.date || defaultResult.date}
            scores={isGameAborted ? [] : result.scores || defaultResult.scores}
            fullTeams={fullTeams}
          />
        );
      case RESULT_SWITCH.timeLine.id:
        return (
          result.timeLine && (
            <TimeLineScreen
              timeLine={result.timeLine}
              fullTeams={fullTeams}
              formattedTimeLine={result.formattedTimeLine}
            />
          )
        );
      case RESULT_SWITCH.trainer.id:
        return result.records && <TrainerScreen records={result.records} fullTeams={fullTeams} />;
      default:
        return null;
    }
  };
  const printHandler = () => {
    setRenderPrint(true);
    setTimeout(() => {
      print();
      setRenderPrint(false);
    }, 1000);
  };
  const allowed = [RESULT_SWITCH.behaviour.id];
  if (result && result.timeLine) {
    allowed.push(RESULT_SWITCH.timeLine.id);
  }
  if (result && result.records) {
    allowed.push(RESULT_SWITCH.trainer.id);
  }
  const showToolbar = !isVideo && result && (result.timeLine || result.records);
  return (
    <>
      <Row className="mt-4 d-print-none">
        <Col xs={4} md={6} lg={7} xl={8}>
          {showToolbar && (
            <PageSwitch t={t} active={active} switchScreen={setActive} allowed={allowed} />
          )}
        </Col>
        <Col xs={4} md={3} lg={2} xl={2}>
          {showToolbar && (
            <Button variant="light" className="print-btn-container" block onClick={printHandler}>
              <FontAwesomeIcon icon={faPrint} />
              {` ${t('_print_')}`}
            </Button>
          )}
        </Col>
        <Col xs={4} md={3} lg={3} xl={2}>
          <Button variant="success" block onClick={finishMission}>
            {`${t('_finish_')} `}
            <FontAwesomeIcon icon={faCheckCircle} />
          </Button>
        </Col>
      </Row>
      {isVideo ? (
        <Video t={t} videoTeams={videoTeams} results />
      ) : (
        <div className="scores-page mt-3">
          {getActivePage()}
          {result.scores && renderPrint && (
            <PrintableTable
              data={result.scores}
              name={result.name}
              date={result.date}
              fullTeams={fullTeams.filter((team) => team.roomType == SESSION_KEY_TYPE.ROOM)}
              timeLine={result.timeLine}
              formattedTimeLine={result.formattedTimeLine}
              duration={result.duration}
            />
          )}
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  isVideo: PropTypes.bool.isRequired,
  videoTeams: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  finishMission: PropTypes.func.isRequired,
  result: PropTypes.oneOfType([PropTypes.shape({}), resultPT]).isRequired,
  isGameAborted: PropTypes.bool.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};
export default Results;
