import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationResources from 'config/translationResources';
import security from 'common/security/security';

export const getCurrentLanguage = () => {
  let language = 'en';
  const user = security.getUser();
  if (user && user.language) {
    language = user.language.split('_')[0];
  }
  return language;
};

export default i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: getCurrentLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
