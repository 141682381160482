/* eslint-disable react/display-name */
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import exportIcon from 'assets/images/export.png';
import moment from 'moment';
import { usersPT } from 'components/User/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list, exportData, importUsers } from 'components/User/userActions';
import { useNavbar } from 'common/components';
import downloader from 'utils/downloader';
import LogoutModal from 'components/Logout/Logout';
import Notifier from 'components/Message/Notifier';
import UploadBtn from 'common/UploadBtn/UploadBtn';

export const correctRows = (t) => [
  {
    Header: t('_id_'),
    sortable: true,
    accessor: (user) => user.id,
    size: 1,
    sorted: 0,
  },
  {
    Header: t('_username_'),
    sortable: true,
    accessor: (user) => user.username,
    size: 1,
  },
  {
    Header: t('_company_'),
    accessor: (user) => user.company,
    sortable: true,
    size: 5,
  },
  {
    Header: t('_units_'),
    accessor: (user) => user.units,
    sortable: true,
    size: 1,
  },
  {
    Header: t('_last_login_date_'),
    sortable: true,
    size: 2,
    accessor: (user) =>
      user.dateLoggedIn ? moment(user.dateLoggedIn).format('YYYY-MM-DD HH:mm') : t('_never_'),
  },
  {
    Header: t('_active_'),
    sortable: true,
    center: true,
    size: 1,
    accessor: (user) => (
      <p className={`circle-point ${!user.active ? 'inactive' : ''}`} title={t('_active_')} />
    ),
  },
  {
    Header: t('_edit_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (user) => (
      <Link to={`/users/edit/${user._id}`}>
        <Button variant="outline-secondary" size="sm" className="edit-session">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ users }) => {
  const [isLogoutModalOpen, openLogoutModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const exportCSV = useCallback(
    () => exportData(({ fileName, value }) => downloader.download(fileName, value)),
    [],
  );

  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('users', files[0], files[0].name);
      importUsers(formData, (data) => {
        dispatch(list());
        Notifier.importReport(data);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  return (
    <Container>
      <LogoutModal
        show={isLogoutModalOpen}
        showPowerOff
        handleClose={() => openLogoutModal(false)}
      />
      {useNavbar('_licensees_', true)}
      <div className="user-page mt-2 mb-2">
        <Nav id="user-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_registered_licensees_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <UploadBtn t={t} onDrop={changeFile} accept={['csv']} isUpload={false} />
            </Nav.Item>
            <Nav.Item as="li">
              <Button variant="secondary-outline" className="nav-icons" onClick={exportCSV}>
                <img alt="" src={exportIcon} />
                {` ${t('_export_')}`}
              </Button>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/users/new">
                <Button variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                  {` ${t('_create_new_')}`}
                </Button>
              </Link>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#user-table" data-offset="0">
          <SortableTable columns={correctRows(t)} data={users} additionalClass="user-page-table" />
        </div>
      </div>
      <div className="user-page-footer">
        <Row>
          <Col className="d-flex justify-content-end footer-list-items" xs={12}>
            <Button
              className="logout-button-link"
              variant="danger"
              onClick={() => openLogoutModal(true)}
            >
              <FontAwesomeIcon icon={faPowerOff} />
              <span>{t('_sign_out_')}</span>
            </Button>
            <Link to="/account/admin">
              <Button variant="outline-secondary">{`${t('_change_settings_')}`}</Button>
            </Link>
            <Link to="/server">
              <Button variant="outline-secondary">{`${t('_server_')}`}</Button>
            </Link>
            <Link to="/log">
              <Button variant="outline-secondary">{`${t('_log_files_')}`}</Button>
            </Link>
            <Link to="/resource">
              <Button variant="outline-secondary">{`${t('_resources_')}`}</Button>
            </Link>
            <Link to="/client">
              <Button variant="outline-secondary">{`${t('_clients_')}`}</Button>
            </Link>
            <Link to="/video">
              <Button variant="outline-secondary">{`${t('_videos_')}`}</Button>
            </Link>
            <Link to="/mission">
              <Button variant="outline-secondary">{`${t('_missions_')}`}</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

List.propTypes = {
  users: usersPT.isRequired,
};

const mapStateToProps = (state) => ({ users: state.users });

export default connect(mapStateToProps, null)(List);
