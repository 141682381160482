import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { TEAMS } from 'config/constants';
import { scoresPT } from 'components/Mission/lib/PropTypes';
import PrintableTimeLine from 'components/Scores/PrintableTimeLine';
import PrintLogo from 'components/Scores/PrintLogo';
import PrintTitle from 'components/Scores/PrintTitle';

const PrintableTable = ({ name, date, data, fullTeams, timeLine, formattedTimeLine, duration }) => {
  const { t } = useTranslation();
  const store = useStore();
  const { user } = store.getState();
  const getItem = (id) => data.filter((item) => item.clientId == id)[0];
  return (
    <>
      <PrintTitle t={t} user={user} name={name} title="_overall_performance_and_human_error_" />
      <Table className="d-none d-print-block print-table">
        <thead>
          <tr>
            <th />
            {fullTeams.map((team) => (
              <th key={`team-key-${team.displayClientId}`}>
                <div>
                  <span className={`team-print-number team-print-number-${team.clientId}`}>
                    {team.displayClientId}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="blue text-uppercase">{t('_total_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return (
                <td className="blue" key={`${'_total_'}-${clientId}`}>
                  {item ? item.total : '-'}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className="green">{t('_distance_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_distance_'}-${clientId}`}>{item ? item.distance : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="green">{t('_jobs_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_jobs_'}-${clientId}`}>{item ? item.job : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="red">{t('_alarm_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_alarm_'}-${clientId}`}>{item ? item.alarm : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="red">{t('_lift_fault_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_lift_fault_'}-${clientId}`}>{item ? item.liftFault : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="red">{t('_disregards_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_disregards_'}-${clientId}`}>{item ? item.disregard : '-'}</td>;
            })}
          </tr>
          <tr className="empty-row" />
          <tr>
            <td className="yellow">{t('_slips_m_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_slips_m_'}-${clientId}`}>{item ? item.slipsM : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="yellow">{t('_slips_s_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_slips_s_'}-${clientId}`}>{item ? item.slipsS : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="yellow">{t('_lapses_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_lapses_'}-${clientId}`}>{item ? item.lapses : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="yellow">{t('_mistakes_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return <td key={`${'_mistakes_'}-${clientId}`}>{item ? item.mistakes : '-'}</td>;
            })}
          </tr>
          <tr>
            <td className="yellow">{t('_violations_m_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return (
                <td key={`${'_violations_m_'}-${clientId}`}>{item ? item.violationsM : '-'}</td>
              );
            })}
          </tr>
          <tr>
            <td className="yellow">{t('_violations_s_')}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return (
                <td key={`${'_violations_s_'}-${clientId}`}>{item ? item.violationsS : '-'}</td>
              );
            })}
          </tr>
        </tbody>
      </Table>
      <PrintLogo t={t} />
      {timeLine && (
        <PrintableTimeLine
          name={name}
          date={date}
          data={timeLine}
          formattedData={formattedTimeLine}
          user={user}
          fullTeams={fullTeams}
          duration={duration}
        />
      )}
    </>
  );
};
PrintableTable.defaultProps = {
  timeLine: {},
  formattedTimeLine: {},
  duration: 0,
};
PrintableTable.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  data: scoresPT.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeLine: PropTypes.shape({}),
  formattedTimeLine: PropTypes.shape({}),
  duration: PropTypes.number,
};
export default PrintableTable;
