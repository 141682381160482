import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/Routes';
import 'config/translation';
import 'config/middlewares';

export const App = () => (
  <BrowserRouter>
    <div className="app">
      <Routes />
    </div>
  </BrowserRouter>
);
export default App;
