import axios from 'axios';
import { API_URL } from 'config/constants';
import { formatTimeLine } from 'utils/resultHelper'

export const list = (sessionId, missionId, listCallback) => {
  const gameReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${missionId}`)
    .then(({ data }) => ({ game: data }));
  const timelineReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${missionId}/timeline`)
    .then(({ data }) => ({ timeLine: data }));
  const recordsReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${missionId}/records`)
    .then(({ data }) => ({ records: data }));
  return Promise.all([gameReq, timelineReq, recordsReq]).then((result) => {
    let timeLine, records, game;
    result.forEach((result) => {
      if (result.timeLine && Object.keys(result.timeLine).length > 0) {
        timeLine = result.timeLine;
      }
      if (result.records && Object.keys(result.records).length > 0) {
        records = result.records;
      }
      if (result.game) {
        game = result.game;
      }
    });
    if (game) {
      game.timeLine = timeLine;
      game.records = records;
      if (timeLine) {
        game.formattedTimeLine = formatTimeLine(timeLine);
      }
    }
    listCallback(game);
  });
};

export default { list };
