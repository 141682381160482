import React from 'react';
import PropTypes from 'prop-types';
import List from 'components/Mission/List';
import ConfirmOptions from 'components/Mission/ConfirmOptions';
import ObserveSimulation from 'components/Mission/ObserveSimulation';
import Results from 'components/Mission/Results';
import { missionPT, sessionPT, missionsPT } from 'components/Mission/PropTypes';
import { userPT } from 'components/User/PropTypes';
import { resultPT } from 'components/Mission/lib/PropTypes';

const Steps = ({
  currentStep,
  missions,
  session,
  currentMission,
  historyExist,
  disconnectTeams,
  playCallback,
  abortSession,
  finishMission,
  user,
  t,
  next,
  prev,
  videoTeams,
  switchGame,
  result,
  isGameAborted,
  fullTeams,
  sendMessage,
  currentGame,
  addTag,
}) => {
  switch (currentStep) {
    case 1:
      return (
        <List
          session={session}
          historyExist={historyExist}
          missions={missions}
          disconnectTeams={disconnectTeams}
          playCallback={playCallback}
          fullTeams={fullTeams}
          sendMessage={sendMessage}
          user={user}
        />
      );
    case 2:
      return (
        <ConfirmOptions
          t={t}
          prev={() => prev(currentStep)}
          next={(data) => next(currentStep, data)}
          currentMission={currentMission}
          user={user}
        />
      );
    case 3:
      return (
        <ObserveSimulation
          t={t}
          isVideo={currentMission.type === '_video_'}
          videoTeams={videoTeams}
          currentMission={currentMission}
          abortSession={abortSession}
          switchGame={switchGame}
          disconnectTeams={disconnectTeams}
          sessionId={session._id}
          currentGame={currentGame}
          addTag={addTag}
        />
      );
    case 4:
      return (
        <Results
          t={t}
          isVideo={currentMission.type === '_video_'}
          videoTeams={videoTeams}
          finishMission={finishMission}
          result={result}
          isGameAborted={isGameAborted}
          fullTeams={fullTeams}
        />
      );
    default:
      return (
        <List
          session={session}
          historyExist={historyExist}
          missions={missions}
          disconnectTeams={disconnectTeams}
          playCallback={playCallback}
          fullTeams={fullTeams}
          sendMessage={sendMessage}
          user={user}
        />
      );
  }
};
Steps.defaultProps = {
  currentGame: 0,
};

Steps.propTypes = {
  t: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  missions: missionsPT.isRequired,
  session: PropTypes.oneOfType([PropTypes.shape({}), sessionPT]).isRequired,
  historyExist: PropTypes.bool.isRequired,
  disconnectTeams: PropTypes.func.isRequired,
  playCallback: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  videoTeams: PropTypes.shape({}).isRequired,
  result: PropTypes.oneOfType([PropTypes.shape({}), resultPT]).isRequired,
  currentMission: PropTypes.oneOfType([PropTypes.shape({}), missionPT]).isRequired,
  user: userPT.isRequired,
  abortSession: PropTypes.func.isRequired,
  finishMission: PropTypes.func.isRequired,
  switchGame: PropTypes.func.isRequired,
  isGameAborted: PropTypes.bool.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  sendMessage: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  currentGame: PropTypes.number,
};
export default Steps;
