import { STATUS } from 'config/constants';
import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const totalsWereChanged = (current, previous) =>
  !current.find(
    ({ clientId, total, gameState }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId && team.total === total && team.gameState === gameState,
      ),
  );

const Totals = () => {
  const teams = useSelector(
    ({ teams }) => teams.map(({ clientId, total, gameState }) => ({ clientId, total, gameState })),
    totalsWereChanged,
  );
  return (
    <>
      {teams.map((team) => (
        <Jumbotron
          className="observe-simulation-total justify-content-center align-items-center"
          key={team.clientId}
        >
          {team.hasOwnProperty('total') &&
          team.gameState !== STATUS.ABORT &&
          'undefined' !== typeof team.total
            ? team.total
            : '-'}
        </Jumbotron>
      ))}
      {/* <Jumbotron
        className="observe-simulation-total justify-content-center align-items-center"
      >0%</Jumbotron> */}
    </>
  );
};
export default Totals;
