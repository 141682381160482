import React, { useCallback, useState } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sessionPT } from 'components/Mission/PropTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faAngleLeft, faBolt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { goBack } from 'utils/common';
import TeamStatusBar from 'components/Mission/lib/TeamStatusBar';
import { ConnectionStatusPanel } from 'components/Mission/ConnectionStatusPanel';
import { SESSION_TYPE, SESSION_KEY_TYPE } from 'config/constants';
import teamsPT from 'components/Mission/lib/PropTypes';

const NavbarComponent = ({ title, isHome, teamStatusBar, session, fullTeams, translate }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (!isHome) {
      goBack(history);
    }
  }, [history, isHome]);
  const handleClickBolt = () => setOpen(true);
  const onCloseEvent = () => {
    setOpen(!open);
  };
  const hasProblem =
    fullTeams.filter(
      (team) =>
        team.roomType !== SESSION_KEY_TYPE.WAITING_ROOM &&
        team.roomType !== SESSION_KEY_TYPE.MISSION_CONTROL &&
        team.clients &&
        !!team.clients.filter((client) => !client.connected).length,
    ).length > 0;
  return (
    <>
      <Navbar bg="light" variant="light">
        <Navbar.Brand>
          <Button variant="outline-secondary" onClick={handleClick}>
            <FontAwesomeIcon icon={isHome ? faHome : faAngleLeft} />
          </Button>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-between navbar-custom-collapse col-xs-5">
          <Navbar.Text>{translate ? t(title) : title}</Navbar.Text>
        </Navbar.Collapse>
        {teamStatusBar ? (
          <>
            {session && (
              <TeamStatusBar
                isDynamicSession={session && session.sessionType == SESSION_TYPE.DYNAMIC}
                fullTeams={fullTeams}
              />
            )}
            {session ? (
              <Button
                variant={hasProblem ? 'danger' : 'light'}
                className="bolt"
                name="bolt"
                onClick={handleClickBolt}
                onKeyDown={handleClickBolt}
                aria-expanded={open}
              >
                <FontAwesomeIcon icon={faBolt} />
              </Button>
            ) : null}
          </>
        ) : (
          ''
        )}
      </Navbar>
      <ConnectionStatusPanel session={session} isOpen={open} onCloseEvent={onCloseEvent} />
    </>
  );
};
NavbarComponent.defaultProps = {
  isHome: false,
  teamStatusBar: false,
  session: null,
  translate: true,
};
NavbarComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isHome: PropTypes.bool,
  teamStatusBar: PropTypes.bool,
  session: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
    }),
    sessionPT,
  ]),
  fullTeams: teamsPT.isRequired,
  translate: PropTypes.bool,
};

export default NavbarComponent;
