export const INIT_MISSION_LIST = 'INIT_MISSION_LIST';
export const ADD_VIDEOS = 'ADD_VIDEOS';
export const UPDATE_TEAMS = 'UPDATE_TEAMS';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const END_GAMES = 'END_GAMES';
export const INIT_TEAMS = 'INIT_TEAMS';
export const RESET_TEAMS = 'RESET_TEAMS';
export const UPDATE_TEAM_KEY = 'UPDATE_TEAM_KEY';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const REMOVE_TAG = 'REMOVE_TAG';
export const RESET_TAGS = 'RESET_TAGS';

export default {
  INIT_MISSION_LIST,
  ADD_VIDEOS,
  UPDATE_TEAMS,
  UPDATE_TEAM,
  END_GAMES,
  INIT_TEAMS,
  RESET_TEAMS,
  UPDATE_TEAM_KEY,
  UPDATE_TAGS,
  REMOVE_TAG,
  RESET_TAGS,
};
