import React, { useState, useCallback, useEffect } from 'react';
import { Container, FormControl, Form, Row, Col, InputGroup } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import ButtonBar from 'components/ButtonBar/ButtonBar';
import { useNavbar } from 'common/components';
import KeysComponent from 'components/Session/lib/KeysComponent';
import { create, update, get, keys, getMeta } from 'components/Session/sessionActions';
import { goBack } from 'utils/common';
import validator from 'utils/validator';
import Notifier from 'components/Message/Notifier';
import { getLanguagesData } from 'components/User/userActions';
import { SESSION_KEY_TYPE, START_NUMBERING, SESSION_TYPE , SESSION_NUMBERING_TYPE } from 'config/constants';

const Session = () => {
  const { sessionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const { user } = store.getState();
  const [name, setName] = useState('');
  const [language, setLanguage] = useState('en_US');
  const [open, setOpen] = useState(false);
  const [clientKeys, setClientKeys] = useState([]);
  const [sessionKey, setSessionKey] = useState('');
  const [sessionHistory, setSessionHistory] = useState({ count: 0, _id: {} });
  const [languages, setLanguages] = useState([
    {
      key: 'en_US',
      name: '_english_',
    },
  ]);
  const [openTeams, setOpenTeams] = useState([true, true, true, true]);
  const [startNumberingAt, setStartNumberingAt] = useState(START_NUMBERING.STANDARD);
  const [sessionType, setSessionType] = useState(SESSION_TYPE.FIXED);
  const [loaded, setLoaded] = useState(false);
  const [sessionNumberingType, setSessionNumberingType] = useState(SESSION_NUMBERING_TYPE.STANDARD);

  const isFormFilled = () =>
    validator.trim(name).length >= 4 &&
    (!open || (open && openTeams.filter((openTeam) => openTeam).length > 0));
  const handleSession = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        !sessionId
          ? create({ name, language, open, openTeams, startNumberingAt, sessionType }, () => {
              Notifier.success('_session_successfully_created_');
              goBack(history);
            })
          : update(
              sessionId,
              { name, language, open, openTeams, startNumberingAt, sessionType },
              () => {
                Notifier.success('_session_successfully_updated_');
                goBack(history);
              },
            ),
      );
    },
    [dispatch, sessionId, name, language, open, openTeams, startNumberingAt, sessionType, history],
  );
  const regenerate = useCallback(() => {
    keys(sessionId, (session) => {
      setSessionKey(session.key);
      setClientKeys(
        session.clientKeys.map((key, index) => {
          if (key.keyType == SESSION_KEY_TYPE.ROOM) {
            key.isOpen = openTeams[index];
          }
          return key;
        }),
      );
    });
  }, [sessionId, openTeams]);

  useEffect(() => {
    getLanguagesData((languagesData) => {
      const langs = languagesData.filter((item) => user.availableLanguages.includes(item._id));
      setLanguages(langs);
      !sessionId && setLanguage(langs[0].key);
      !sessionId && setLoaded(true);
      if (sessionId) {
        get(sessionId, (session) => {
          setName(session.name);
          setLanguage(session.language);
          setSessionKey(session.key);
          setClientKeys(session.clientKeys);
          setOpen(session.open);
          getMeta(sessionId, setSessionHistory);
          setSessionType(session.sessionType);
          setStartNumberingAt(session.startNumberingAt);
          if(session.startNumberingAt > 1) {
            setSessionNumberingType(SESSION_NUMBERING_TYPE.DYNAMIC);
          }
          const teams = session.clientKeys
            .filter((key) => key.keyType == SESSION_KEY_TYPE.ROOM)
            .map((item) => item.isOpen);
          setOpenTeams(teams);
          setLoaded(true);
        });
      }
    });
  }, [sessionId, user.availableLanguages]);

  const handleOpenTeamsChange = useCallback(
    (index) => {
      openTeams[index] = !openTeams[index];
      setOpenTeams([...openTeams]);
    },
    [openTeams],
  );
  const handlesSessionNumberingTypeChange = (event) => {
    const type = Number(event.target.value);
    if(type === SESSION_NUMBERING_TYPE.STANDARD) {
      setStartNumberingAt(START_NUMBERING.STANDARD);
    }
    setSessionNumberingType(type);
  };
  const handleStartNumberingChange = (event) => {
    setStartNumberingAt(event.target.value);
  };

  const handleSessionTypeChange = (event) => {
    setSessionType(Number(event.target.value));
  };
  return (
    <Container>
      {useNavbar('_configure_session_')}
      <Form
        className="session-form mt-5"
        method="post"
        onSubmit={handleSession}
        noValidate
        validated={loaded}
      >
        <Form.Group as={Row} controlId="formName">
          <Form.Label
            column
            xs={{ span: 2, offset: 1 }}
            sm={{ span: 2, offset: 1 }}
            md={{ span: 2, offset: 1 }}
          >
            {t('_name_')}
          </Form.Label>
          <Col xs={9} sm={9} md={9} lg={7}>
            <FormControl
              value={name}
              pattern=".{4,}"
              placeholder={t('_enter_your_session_name_')}
              required
              name="name"
              onChange={(e) => setName(e.target.value.trimLeft())}
            />
            <Form.Control.Feedback type="invalid">
              {t('_session_name_error_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLanguage">
          <Form.Label
            column
            xs={{ span: 2, offset: 1 }}
            sm={{ span: 2, offset: 1 }}
            md={{ span: 2, offset: 1 }}
          >
            {t('_language_')}
          </Form.Label>
          <Col xs={9} sm={9} md={9} lg={7}>
            <Form.Control
              value={language}
              as="select"
              required
              name="language"
              placeholder={t('_language_')}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {languages.map((language) => (
                <option key={language.key} value={language.key}>
                  {t(language.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formSessionStatus" className="mt-5">
          <Form.Label column xs={{ span: 3, offset: 0 }} md={{ span: 2, offset: 1 }}>
            {t('_session_status_')}
          </Form.Label>
          <Col xs={2} md={4} lg={3} className="mb-3 switch-button">
            <BootstrapSwitchButton
              checked={open}
              onlabel={t('_open_')}
              offlabel={t('_closed_')}
              onstyle="light"
              offstyle="light"
              border
              width={210}
              onChange={setOpen}
            />
          </Col>
          <Col
            xs={{ span: 7, offset: 0 }}
            lg={{ span: 4 }}
            md={{ span: 5 }}
            className="session-status"
          >
            <InputGroup className="mb-3 justify-content-end">
              {openTeams.map((openTeam, id) => (
                <InputGroup.Prepend key={`input-${id + 1}`}>
                  <InputGroup.Text>{id + 1}</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={openTeam}
                    onChange={() => handleOpenTeamsChange(id)}
                  />
                </InputGroup.Prepend>
              ))}
            </InputGroup>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="startNumberingAt" className="mt-4">
          <Form.Label column xs={{ span: 3, offset: 0 }} md={{ span: 2, offset: 1 }}>
            {t('_team_numbering_')}
          </Form.Label>
          <Col xs={7} md={6} lg={5}>
            <InputGroup>
              <InputGroup.Prepend className="bblr-0 border-bottom-0">
                <InputGroup.Radio
                  name="standard-temas"
                  onChange={handlesSessionNumberingTypeChange}
                  value={SESSION_NUMBERING_TYPE.STANDARD}
                  checked={SESSION_NUMBERING_TYPE.STANDARD == sessionNumberingType}
                />
              </InputGroup.Prepend>
              <FormControl
                className="custom-form-control bbrr-0"
                disabled
                placeholder={t('_standard_teams_')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend className="btlr-0">
                <InputGroup.Radio
                  name="standard-temas"
                  onChange={handlesSessionNumberingTypeChange}
                  value={SESSION_NUMBERING_TYPE.DYNAMIC}
                  checked={SESSION_NUMBERING_TYPE.DYNAMIC == sessionNumberingType}
                />
              </InputGroup.Prepend>
              <FormControl
                className="custom-form-control"
                disabled
                placeholder={t('_start_numbering_at_')}
              />
              <FormControl
                className="team-form-control btrr-0"
                placeholder={t('_type_in_number_')}
                type="number"
                min="1"
                max="96"
                disabled={SESSION_NUMBERING_TYPE.STANDARD == sessionNumberingType}
                value={startNumberingAt}
                onChange={handleStartNumberingChange}
              />
              <Form.Control.Feedback type="invalid" className="start-numbering-at">
                {t('_start_numbering_message_')}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formSessionType" className="mb-5 mt-5">
          <Form.Label column xs={{ span: 3, offset: 0 }} md={{ span: 2, offset: 1 }}>
            {t('_session_type_')}
          </Form.Label>
          <Col xs={7} md={6} lg={5}>
            <InputGroup>
              <InputGroup.Prepend className="bblr-0">
                <InputGroup.Radio
                  name="session-type"
                  onChange={handleSessionTypeChange}
                  value={SESSION_TYPE.FIXED}
                  checked={SESSION_TYPE.FIXED === sessionType}
                />
              </InputGroup.Prepend>
              <FormControl
                className="custom-form-control bbrr-0"
                disabled
                placeholder={t('_static_session_')}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend className="btlr-0">
                <InputGroup.Radio
                  name="session-type"
                  onChange={handleSessionTypeChange}
                  value={SESSION_TYPE.DYNAMIC}
                  checked={SESSION_TYPE.DYNAMIC === sessionType}
                />
              </InputGroup.Prepend>
              <FormControl
                className="custom-form-control btrr-0"
                disabled
                placeholder={t('_dynamic_session_')}
              />
            </InputGroup>
          </Col>
        </Form.Group>

        {sessionId && clientKeys.length ? (
          <KeysComponent sessionKey={sessionKey} keys={clientKeys} regenerate={regenerate} />
        ) : null}
        <ButtonBar
          historyExist={sessionHistory.count > 0}
          isFormFilled={isFormFilled()}
          saveActionType="submit"
          sessionId={sessionId}
        />
      </Form>
    </Container>
  );
};

export default Session;
