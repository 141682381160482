import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import AreaChart from '@bit/recharts.recharts.area-chart';
import Area from '@bit/recharts.recharts.area';

const DELTA = {
  56: 10,
  28: 5,
};

const getPadding = (isLarge, heightDelta) => {
  if (!heightDelta) {
    return 0;
  }
  if (isLarge) {
    return heightDelta.toFixed(2);
  }
  return (heightDelta * 1 - 1).toFixed(2);
};

function StackedAreaChart({ data, color, max, inverted, height }) {
  const [width, setWidth] = useState(800);
  const wrapperRef = useRef();
  const handleResize = useCallback(() => {
    let myWidth = wrapperRef.current.getBoundingClientRect().width;
    if (!myWidth) {
      myWidth =
        (
          wrapperRef.current.parentElement.parentElement.parentElement.parentElement.parentElement
            .clientWidth * 0.7187
        ).toFixed(2) *
          1 -
        135;
    }
    setWidth(myWidth);
  }, []);
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const dataMax = data.reduce((y, { y: current }) => {
    if (y < current) {
      return current;
    }
    return y;
  }, 0);

  const maxDelta = DELTA[height];

  const chartHeight = (height * dataMax) / max;
  const heightDelta = height - chartHeight;

  return (
    <div
      ref={wrapperRef}
      style={{
        width: '100%',
        height: '100%',
        paddingTop: `${getPadding(height === 56, heightDelta, maxDelta, chartHeight)}px`,
        transform: inverted ? 'rotateX(180deg)' : 'rotateX(0)',
      }}
    >
      <AreaChart
        width={width}
        height={chartHeight}
        data={data}
        maxBarSize={max}
        margin={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Area
          type="monotone"
          height={chartHeight}
          dataKey="y"
          stroke="none"
          fill={color}
          fillOpacity={1}
          isAnimationActive={false}
        />
      </AreaChart>
    </div>
  );
}

StackedAreaChart.defaultProps = {
  data: [],
};

StackedAreaChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  color: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  inverted: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

export default StackedAreaChart;
