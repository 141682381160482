import axios from 'axios';
import { API_URL } from 'config/constants';
import { INIT_SETTINGS, USB_KSY_CHANGED } from 'components/Settings/settingsActionTypes';
import security from 'common/security/security';

export const getSettingsData = () => (dispatch) => {
  axios.get(`${API_URL}settings/data/`).then(({ data }) => {
    security.setLanguage(data.languages);
    dispatch({
      type: INIT_SETTINGS,
      groups: data.groups,
      languages: data.languages,
    });
  });
};

export const usbKeyChanged = (usbKey) => ({ type: USB_KSY_CHANGED, usbKey });

export default { getSettingsData, usbKeyChanged };
