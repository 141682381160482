import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTES, ROLES } from 'config/constants';
import { AuthController, withPermissionControl } from 'config/permissionController';
import LoginRoutes from 'components/Login/Routes';
import SessionRoutes from 'components/Session/Routes';
import AccountRoutes from 'components/Account/Routes';
import UserRoutes from 'components/User/Routes';
import UnitRoutes from 'components/Unit/Routes';
import ResourceRoute from 'components/Resource/Routes';
import AdminMissionRoute from 'components/AdminMission/Routes';
import Log from 'components/Log/Log';
import ClientRoute from 'components/Client/Routes';
import VideoRoute from 'components/Video/Routes';
import ServerRoute from 'components/Server/Routes';

export const Routes = () => (
  <Router>
    <Switch>
      <Route
        path={ROUTES.LOGIN}
        component={withPermissionControl(LoginRoutes, {
          allowUnauthorized: true,
          home: ROUTES.ROOT,
        })}
      />
      <Route
        path={ROUTES.SESSIONS}
        component={withPermissionControl(SessionRoutes, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.licensee.role],
        })}
      />
      <Route
        path={ROUTES.ACCOUNT}
        component={withPermissionControl(AccountRoutes, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.licensee.role, ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.UNITS}
        component={withPermissionControl(UnitRoutes, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.licensee.role],
        })}
      />
      <Route
        path={ROUTES.USERS}
        component={withPermissionControl(UserRoutes, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.RESOURCES}
        component={withPermissionControl(ResourceRoute, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.ADMIN_MISSION}
        component={withPermissionControl(AdminMissionRoute, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.LOG}
        component={withPermissionControl(Log, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.CLIENT}
        component={withPermissionControl(ClientRoute, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.VIDEO}
        component={withPermissionControl(VideoRoute, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route
        path={ROUTES.SERVER}
        component={withPermissionControl(ServerRoute, {
          allowAuthorized: true,
          home: ROUTES.ROOT,
          allowedRoles: [ROLES.admin.role],
        })}
      />
      <Route path={ROUTES.ROOT} component={AuthController} />
    </Switch>
  </Router>
);

export default Routes;
