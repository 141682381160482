import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RemainingTime from 'components/Mission/lib/RemainingTime';
import triangle from 'assets/icons/triangle_bordered.png';
import circle from 'assets/icons/circle_bordered.png';
import square from 'assets/icons/square_bordered.png';
import { TAGS } from 'config/constants';

export const teamStatusWasChanged = (current, previous) =>
  current.find(
    ({ clientId, tags }) =>
      !previous.find((team) => team.clientId == clientId && team.tags.length !== tags.length),
  );

const TimeLine = ({ duration, currentGame }) => {
  const progress = useSelector(({ teams }) => {
    let time = 0;
    teams.forEach((team) => {
      if (time < team.time) {
        time = team.time;
      }
    });
    if (time >= duration) {
      return 100;
    }
    return (time / duration) * 100;
  });
  const teams = useSelector(
    ({ teams }) =>
      teams.map(({ clientId, tags }) => ({
        clientId,
        tags,
      })),
    teamStatusWasChanged,
  );
  const getImage = (id) => {
    let image = null;
    switch (id) {
      case TAGS.TRIANGLE:
        image = triangle;
        break;
      case TAGS.CIRCLE:
        image = circle;
        break;
      case TAGS.SQUARE:
        image = square;
        break;
    }
    return image;
  };
  const team = teams.filter(({ clientId }) => clientId == currentGame)[0] || { tags: [] };
  return (
    <div className="observe-simulation-time-line">
      <div>
        <div className="observe-simulation-time-line-progress" style={{ width: `${progress}%` }} />
        <div className="observe-simulation-time-line-tools">
          {team.tags.map(
            ({ eventTime, time, eventData }) =>
              eventTime <= duration && (
                <div key={time} style={{ left: `calc(${(eventTime / duration) * 100}% - 10px)` }}>
                  <img alt="" src={getImage(eventData.tagId)} />
                </div>
              ),
          )}
        </div>
      </div>
      <div>
        <RemainingTime duration={duration} />
      </div>
    </div>
  );
};
TimeLine.propTypes = {
  duration: PropTypes.number.isRequired,
  currentGame: PropTypes.number.isRequired,
};
export default TimeLine;
