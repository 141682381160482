import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Jumbotron, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toTime } from 'utils/common';
import { GAME_DECKS } from 'config/constants';

export const teamStatusWasChanged = (current, previous) =>
  current.find(
    ({ clientId, roles, isReady, selected }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.isReady === isReady &&
          team.selected === selected &&
          team.roles[GAME_DECKS.MAIN.id] === roles[GAME_DECKS.MAIN.id] &&
          team.roles[GAME_DECKS.SUPPORT.id] === roles[GAME_DECKS.SUPPORT.id] &&
          team.roles[GAME_DECKS.COMMANDER.id] === roles[GAME_DECKS.COMMANDER.id],
      ),
  );

const defaultInfo = {
  main: false,
  support: false,
  commander: false,
  fullTeam: false,
};

const ConfirmOptions = ({ t, prev, next, currentMission, user }) => {
  const teams = useSelector(({ teams }) => teams, teamStatusWasChanged);
  const [info, setInfo] = useState({
    ...defaultInfo,
    teams: [...teams],
    disabled: true,
    play: true,
    units: currentMission.units * teams.filter((team) => team.selected).length,
  });
  const [submited, setSubmited] = useState(false);
  const toggleTeam = useCallback(
    (clientId) => {
      const updatedTeams = info.teams.map((team) => {
        if (team.clientId === clientId) {
          team.selected = !team.selected;
        }
        return team;
      });
      const teamLength = updatedTeams.filter((team) => team.selected).length;
      setInfo({
        ...info,
        teams: updatedTeams,
        disabled: !teamLength,
        units: currentMission.units * teamLength,
      });
    },
    [currentMission.units, info],
  );
  useEffect(() => {
    const updatedInfo = { ...defaultInfo, disabled: true, teams, play: true };
    teams.forEach((team) => {
      if (team.isReady) {
        updatedInfo.disabled = false;
        if (team.roles[GAME_DECKS.MAIN.id]) {
          updatedInfo.main = true;
        }
        if (team.roles[GAME_DECKS.SUPPORT.id]) {
          updatedInfo.support = true;
        }
        if (team.roles[GAME_DECKS.COMMANDER.id]) {
          updatedInfo.commander = true;
        }
        if (team.isFull) {
          updatedInfo.fullTeam = true;
        }
        if (!team.hasOwnProperty('selected')) {
          team.selected = team.isReady;
        }
      }
    });
    updatedInfo.units = currentMission.units * teams.filter((team) => team.selected).length;
    setInfo(updatedInfo);
  }, [currentMission.units, teams]);
  return (
    <>
      <Row>
        <Col>
          <Row className="mt-5 mb-3">
            <Col>
              <strong>{t('_select_teams_')}</strong>
            </Col>
          </Row>
          {info.teams.map((team) => (
            <Form.Group as={Col} className="selector-checkbox" key={team.clientId}>
              <Form.Check
                type="checkbox"
                label={`${t('_team_')} ${team.clientId}`}
                disabled={!team.isReady}
                checked={!!team.selected}
                onChange={() => toggleTeam(team.clientId)}
              />
            </Form.Group>
          ))}
        </Col>
        <Col>
          <Row className="mt-5 mb-3">
            <Col>
              <strong>{t('_choose_play_options_')}</strong>
            </Col>
          </Row>
          {currentMission.type == '_video_' && (
            <>
              <Form.Group className="selector-checkbox">
                <Form.Check
                  type="checkbox"
                  label={t('_play_on_0_')}
                  checked={info.main}
                  onChange={() => setInfo({ ...info, main: !info.main })}
                />
              </Form.Group>
              <Form.Group className="selector-checkbox">
                <Form.Check
                  type="checkbox"
                  label={t('_play_on_1_')}
                  checked={info.support}
                  onChange={() => setInfo({ ...info, support: !info.support })}
                />
              </Form.Group>
              <Form.Group className="selector-checkbox">
                <Form.Check
                  type="checkbox"
                  label={t('_play_on_2_')}
                  checked={info.commander}
                  disabled={!info.commander}
                  onChange={() => setInfo({ ...info, commander: !info.commander })}
                />
              </Form.Group>
            </>
          )}
        </Col>
        <Col>
          <Row className="mt-5 mb-3" />
          <Jumbotron className="mission-units-bar">
            <p>{t('_duration_')}</p>
            <p>
              <strong>{`${toTime(currentMission.duration)} ${t('_minutes_')}`}</strong>
            </p>
            <p>{t('_your_total_investment_')}</p>
            <p>
              <strong>
                <span className="units-big">{info.units} </span>
                {t('_units_')}
              </strong>
            </p>
          </Jumbotron>
        </Col>
      </Row>
      <div className="mt-2 d-flex justify-content-between">
        <Button variant="danger" onClick={prev} className="col-4 col-md-3">
          <FontAwesomeIcon icon={faTimesCircle} />
          {` ${t('_cancel_')}`}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            setSubmited(true);
            next(info);
          }}
          className="col-4 col-md-3"
          disabled={
            info.disabled ||
            user.units < info.units ||
            submited ||
            (!info.main && !info.support && !info.commander)
          }
        >
          {`${t('_confirm_and_play_')} `}
          <FontAwesomeIcon icon={faPlayCircle} />
        </Button>
      </div>
    </>
  );
};

ConfirmOptions.propTypes = {
  t: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  user: PropTypes.shape({
    units: PropTypes.any.isRequired,
  }).isRequired,
  currentMission: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    units: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConfirmOptions;
