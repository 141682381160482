import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';
import { TEAMS, TIME_LINE_TABS } from 'config/constants';

const TimeLineTools = ({
  t,
  activeTab,
  changeTab,
  activeTeam,
  changeTeam,
  timeLine,
  fullTeams,
}) => {
  const getDisplayClientId = (clientId) =>
    fullTeams.find((team) => team.clientId === clientId).displayClientId || clientId;
  return (
    <div className="scores-page-tools row mb-2 d-print-none">
      <ButtonGroup className="trainer-screen-switch" toggle>
        {TEAMS.map((team) => (
          <Button
            variant="light"
            key={`team-client-${team.clientId}`}
            onClick={() => changeTeam(team.clientId)}
            disabled={!timeLine[team.clientId]}
            className={`${activeTeam == team.clientId ? 'active' : ''}`}
          >
            {t('_team_')} {getDisplayClientId(team.clientId)}
          </Button>
        ))}
      </ButtonGroup>
      <div className="scores-page-tools-tabs">
        <ButtonGroup className="scores-page-tools-tabs-group">
          <Button
            onClick={() => changeTab(TIME_LINE_TABS.system_status.key)}
            active={TIME_LINE_TABS.system_status.key == activeTab}
          >
            {t(TIME_LINE_TABS.system_status.title)}
          </Button>
          <Button
            onClick={() => changeTab(TIME_LINE_TABS.infos_jobs.key)}
            active={TIME_LINE_TABS.infos_jobs.key == activeTab}
          >
            {t(TIME_LINE_TABS.infos_jobs.title)}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

TimeLineTools.propTypes = {
  t: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  activeTeam: PropTypes.number.isRequired,
  changeTeam: PropTypes.func.isRequired,
  timeLine: PropTypes.shape({}).isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};
export default TimeLineTools;
