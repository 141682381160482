import { TEAMS } from 'config/constants';
import {
  UPDATE_TEAMS,
  UPDATE_TEAM,
  END_GAMES,
  INIT_TEAMS,
  RESET_TEAMS,
  UPDATE_TEAM_KEY,
  UPDATE_TAGS,
  REMOVE_TAG,
  RESET_TAGS,
} from './missionActionTypes';

export const initialState = TEAMS;

export const updateTeam = (team, updates) => {
  updates &&
    Object.keys(updates).forEach((key) => {
      team[key] = updates[key];
    });
  const keyList = Object.keys(team);
  team.initialized =
    keyList.includes('lift') &&
    keyList.includes('infoJob') &&
    keyList.includes('indicators') &&
    keyList.includes('generator') &&
    keyList.includes('alarm') &&
    keyList.includes('solarReceptor') &&
    keyList.includes('total');
  return team;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TEAMS:
      return state.map((team) => {
        const newTeam = action.teams.find((newTeam) => newTeam.clientId === team.clientId);
        return updateTeam(team, newTeam);
      });
    case UPDATE_TEAM:
      return state.map((team) => {
        if (team.clientId == action.team.clientId) {
          return updateTeam(team, action.team);
        }
        return team;
      });
    case END_GAMES:
      return state.map((team) => {
        team.isGameIsStarted = false;
        team.tags = [];
        return team;
      });
    case INIT_TEAMS:
      return JSON.parse(JSON.stringify(TEAMS));
    case RESET_TEAMS:
      return state.map(({ clientId, roles }) => ({
        clientId,
        roles: { ...roles, 0: false, 1: false, 2: false },
        tags: [],
        clients: [],
        initialized: false,
        isGameIsStarted: null,
      }));
    case UPDATE_TEAM_KEY:
      return state.map((team) => {
        if (team.key == action.team.key) {
          team = { ...team, ...action.team };
        }
        return team;
      });
    case UPDATE_TAGS:
      return state.map((team) => {
        const teamTags = team.tags || [];
        const tags = (action.tags[team.clientId] || []).filter(
          (tag) =>
            !teamTags.find(
              (teamTag) =>
                teamTag.eventTime == tag.eventTime &&
                teamTag.time == tag.time &&
                teamTag.eventData.tagId == tag.eventData.tagId,
            ),
        );
        team.tags = [...teamTags, ...tags];
        return team;
      });
    case RESET_TAGS:
      return state.map((team) => {
        team.tags = [];
        return team;
      });
    case REMOVE_TAG:
      return state.map((team) => {
        const teamTags = team.tags || [];
        const tagToRemove = action.tag[team.clientId];
        if (tagToRemove) {
          team.tags = teamTags.filter(
            (teamTag) =>
              !(
                teamTag.eventTime == tagToRemove.eventTime &&
                teamTag.eventData.tagId == tagToRemove.eventData.tagId
              ),
          );
        }
        return team;
      });
    default:
      return state;
  }
}
